import styled from "styled-components";

export const StyledMenu = styled.nav`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #000;
  height: 100vh;
  text-align: left;
  padding-top: 10rem;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 10;
  width: 100%;
  .link a {
    line-height: 3em;
    text-transform: uppercase;
    padding: 2rem;
    margin-top: 1em;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s linear;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    line-height: 2em;
    text-align: center;
    font-size: 2.5rem;
    &:hover {
      color: #00adef;
    }
  }

  @media only screen and (min-width: 768px) {
    width: auto;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    .MenuMobile {
      display: none;
    }
  }
`;

StyledMenu.defaultProps = {
  openStyle: false,
};
