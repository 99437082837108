import React from "react";
import { Link } from "gatsby";
import { StyledMenu } from "./menu.css.js";

const Menu = ({ links, open }) => {
  return (
    <StyledMenu open={open}>
      {links.menuLinks.primaire.map((link) => (
        <div className="MenuMobile link">
          <Link to={link.lien}>{link.titre}</Link>
        </div>
      ))}
      {links.menuLinks.produits.map((link) => (
        <div className="Menu link">
          <Link to={link.lien}>{link.titre}</Link>
        </div>
      ))}
      {links.menuLinks.services.map((link) => (
        <div className="Menu link">
          <Link to={link.lien}>{link.titre}</Link>
        </div>
      ))}
      <div className="Menu button">
        <Link to={links.menuLinks.apropos.link}>{links.menuLinks.apropos.titre}</Link>
      </div>
    </StyledMenu>
  );
};

export default Menu;
