import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from './header.css';
import Menu from 'components/menu';
import Burger from 'components/burger';
import Logo from '../../img/svg/logo_MU2.svg?name=logo';

const Header = (menuLinks) => {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <div className="hidden">
        <p>{menuLinks.menuLinks.alert}</p>
      </div>
      <div className="HeaderGroup">
        <div id="burger">
          <Burger open={open} setOpen={setOpen} />
        </div>
        <Link to={menuLinks.menuLinks.accueil}>
          <div id="logo">
            <Logo />
          </div>
        </Link>
        {menuLinks.menuLinks.primaire.map((link) => (
          <div className="lien"><Link to={link.lien}>{link.titre}</Link></div>
        ))}
      </div>
      <Menu open={open} setOpen={setOpen} links={menuLinks} />
    </Container>
  );
};

Header.propTypes = {
  menuLinks: PropTypes.object.isRequired,
};

export default Header;
