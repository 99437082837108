import styled from 'styled-components';

export const Container = styled.footer`
  .footer {
    width: 100%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: black;
    font-size: 1.5em;
  }
  .footer a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
  }
  #copyright,
  #copyright a {
    color: #F2F2F2;
    text-align: center;
    margin-top: 5px;
    font-size: 0.8em;
  }
  #social,
  #social a {
    color: white;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1200px) {
    #social,
    #social a {
      margin-top: 12px;
    }
  }
`;
