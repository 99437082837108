import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from './global.css.js';
import Head from 'components/head';


const Layout = ({ children, lang, pageTitle, menuLinks }) => {
  return (<div>
    <Head pageTitle={pageTitle} lang={lang} />
    <GlobalStyle />
    <Header menuLinks={menuLinks} />

    <div className="content">{children}</div>
    <Footer />
  </div>);
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  menuLinks: PropTypes.object.isRequired
};

export default Layout;
