import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    background-color: #F2F2F2;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }


  video {
    max-width: 100%;
  }

  .content{
  margin: 0 auto;
  max-width: 1800px;
  padding: 0px, 1.0875rem, 1.45rem;
  padding-Top: 0;
  min-height: calc(100vh - 70px);
}
.page404 {
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 4rem;
}
.typeform {
    display: block;
    position: relative;
    height: 600px;
    margin-bottom: 15px;
    margin-right: 2em;
    margin-left: 2em;
  }
`;
