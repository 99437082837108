import styled from "styled-components";

export const Container = styled.header`
  .HeaderGroup {
    width: 100%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
  }
  .lien {
    display: none;
  }

  #burger {
    position: absolute;
    margin: 10px;
  }

  #logo {
    width: 100%;
    height: auto;
  }

  .Alert {
    background-color: #d50000;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem;
    color: #ffffff;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .HeaderGroup a {
    justify-self: start;
    text-decoration: none;
    color: black;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #034F81;
  }
  .lien:hover a {
    color: #00ADEF;
  }

  @media only screen and (min-width: 768px) {
    .HeaderGroup {
      display: grid;
      grid-template-columns: 90px auto 150px 150px 150px 80px;
      align-items: center;
      justify-items: center;
    }

    .lien {
      display: grid;
    }
    #burger {
      justify-self: center;
      position: static;
    }

    #logo {
      width: 100%;
      height: auto;
      justify-self: start;
    }

    .HeaderGroup a {
      font-size: 2.5rem;
    }

    .Alert p {
      font-size: 1.5rem;
    }
  }
  @media only screen and (min-width: 1024px) {
  }
`;
