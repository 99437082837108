import React from 'react';
import { Container } from './footer.css';

const Footer = () => (
  <Container>
    <div className="footer">
      <h5 id="social">
        <a href="https://www.linkedin.com/company/30669817/">LinkedIn |</a>
        <a href="https://www.linkedin.com/company/30669817/"> Twitter |</a>
        <a href="https://www.linkedin.com/company/30669817/"> Medium</a>
      </h5>
      <h6 id="copyright">MU2 © 2020</h6>
    </div>
  </Container>
);
export default Footer;
